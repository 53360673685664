<template>
  <transition name="fade">
    <router-view></router-view>
  </transition>
</template>

<script>
  import { i18nHelper } from '@/utils';

  export default {
    name: 'Teaser',
    metaInfo: {
      title: i18nHelper.getMessage('meta.title.signUp'),
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: i18nHelper.getMessage('meta.description.signUp')
        }
      ]
    }
  };
</script>
